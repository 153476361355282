@import ../styles/variables

// swiper-pagination swiper-pagination-clickable swiper-pagination-bullets
.slider-vertical
    .swiper-pagination
        margin-right: 1%
        height: 20%
        width: 8px
        @include flexColumn()
        
        @media screen and ( max-width: 992px )
            display: none
        
        .bullet
            width: 8px
            height: 8px
            border-radius: 50%
            background-color: #000
            border: .1px solid #fff

            &:nth-child(1).bulletActive
                background-color: #4DC9FF
                transform: scale(1.2)

            &:nth-child(2).bulletActive
                background-color: #4DC9FF
                transform: scale(1.2)

            &:nth-child(3).bulletActive
                background-color: #8BEB89
                transform: scale(1.2)

            &:nth-child(4).bulletActive
                background-color: #FFC6A1
                transform: scale(1.2)

            &:nth-child(5).bulletActive
                background-color: #FFA6A0
                transform: scale(1.2)

            &:nth-child(6).bulletActive
                background-color: #5143F0
                transform: scale(1.2)

                