svg
    position: fixed
    height: 0%
    z-index: -1
    -webkit-transform: translateZ(0)
    -webkit-backface-visibility: hidden

.wrapper 
    z-index: -100
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    filter: url('#filter')
    -webkit-transform: translateZ(0)
    -webkit-backface-visibility: hidden

    @media screen and ( max-width: 922px )
        position: absolute
        width: 100vw
        height: 100vh
        overflow: hidden

    .ball-right
        width: 0
        height: 0
        position: absolute
        top: 30% 
        -webkit-transition: .5s
        -moz-transition: .5s
        -o-transition: .5s
        transition: .5s
        -webkit-transform: translateZ(0)
        -webkit-backface-visibility: hidden
        overflow-y: hidden
        
        @media screen and ( max-width: 922px )
            overflow-y: visible
            
        &.show
            display: block
            width: 800px
            height: 800px
            -webkit-transition: 3s
            -moz-transition: 3s
            -o-transition: 3s
            transition: 3s
            @media screen and ( max-width: 2600px )
                width: 600px
                height: 600px
            @media screen and ( max-width: 1800px )
                width: 450px
                height: 450px
            @media screen and ( max-width: 1500px )
                width: 400px
                height: 400px
            @media screen and ( max-width: 1000px )
                width: 300px
                height: 300px

        &:before 
            border-radius: 100%
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            content: ''

        &:nth-child(1) 
            right: 7%
            top: 40%
            -webkit-animation: gone 3s, scale1 5s infinite  alternate ease-in-out
            -moz-animation: gone 3s, scale1 5s infinite  alternate ease-in-out
            -o-animation: gone 3s, scale1 5s infinite  alternate ease-in-out
            animation: gone 3s, scale1 5s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 5s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s
        &:nth-child(2) 
            right: 10%
            top: 43%
            -webkit-animation: gone 3s, scale2 2s infinite  alternate ease-in-out
            -moz-animation: gone 3s, scale2 2s infinite  alternate ease-in-out
            -o-animation: gone 3s, scale2 2s infinite  alternate ease-in-out
            animation: gone 3s, scale2 2s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s
        &:nth-child(3) 
            right: 17%
            top: 30%
            -webkit-animation: gone 3s, scale3 6s infinite  alternate ease-in-out
            -moz-animation: gone 3s, scale3 6s infinite  alternate ease-in-out
            -o-animation: gone 3s, scale3 6s infinite  alternate ease-in-out
            animation: gone 3s, scale3 6s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s
        &:nth-child(4) 
            right: 17%
            top: 50%
            -webkit-animation: gone 3s ease-in, scale4 4s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale4 4s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale4 4s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale4 4s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s
        &:nth-child(5) 
            right: 27%
            top: 10%
            -webkit-animation: gone 3s ease-in, scale5 3s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale5 3s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale5 3s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale5 3s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s
        &:nth-child(6) 
            right: -7%
            top: 20%
            -webkit-animation: gone 3s ease-in, scale6 4s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale6 4s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale6 4s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale6 4s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s

        &:nth-child(7) 
            right: 10%
            top: 10%
            -webkit-animation: gone 3s ease-in, scale7 5s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale7 5s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale7 5s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale7 5s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s

        &:nth-child(8) 
            right: 7%
            top: 5%
            -webkit-animation: gone 3s ease-in, scale8 5s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale8 5s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale8 5s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale8 5s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s

        &:nth-child(9) 
            right: 7%
            top: 0%
            -webkit-animation: gone 3s ease-in, scale8 8s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale8 8s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale8 8s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale8 8s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s

        &:nth-child(10) 
            right: 7%
            top: 15%
            -webkit-animation: gone 3s ease-in, scale8 2s infinite  alternate ease-in-out
            -moz-animation: gone 3s ease-in, scale8 3s infinite  alternate ease-in-out
            -o-animation: gone 3s ease-in, scale8 3s infinite  alternate ease-in-out
            animation: gone 3s ease-in, scale8 3s infinite  alternate ease-in-out
            -webkit-animation-delay: 0s, 1s
            -moz-animation-delay: 0s, 5s
            -o-animation-delay: 0s, 5s
            animation-delay: 0s, 5s

    
    


        