.slider-vertical
    .swiper-wrapper 
        -webkit-transform: translateZ(0)
        -webkit-transform: translate3d(0, 0, 0)
        -webkit-backface-visibility: hidden
        height: 100vh !important
        overflow-y: visible

        .swiper-slide
            overflow: hidden
            height: auto !important
            -webkit-transform: translate3d(0, 0, 0)
            -webkit-transform: translateZ(0)
            -webkit-backface-visibility: hidden

            // @media screen and ( max-width: 992px )
            //     overflow-y: visible
            //     overflow-x: hidden
                
                

                
        
            
