h1, h2, h3, p,ul,li
    list-style: none
    margin-block-start: 0
    margin-block-end: 0
    padding-inline-start: 0

button 
    cursor: pointer

a
    text-decoration: none