@keyframes scale1
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.2)

@keyframes scale1r
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.2)

@keyframes scale2
    0% 
        transform: scale(1)
    100%  
        transform: scale(2)

@keyframes scale2r
    0% 
        transform: scale(1)
    100%  
        transform: scale(2)

@keyframes scale3
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.1)

@keyframes scale3r
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.1)

@keyframes scale4
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.2)

@keyframes scale4r
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.2)

@keyframes scale5
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.6)

@keyframes scale5r
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.6)

@keyframes scale6
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.4)

@keyframes scale6r
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.4)

@keyframes scale7
    0% 
        transform: scale(1)
    100%  
        transform: scale(2.3)
        
@keyframes scale7r
    0% 
        transform: scale(1)
    100%  
        transform: scale(2.3)

@keyframes scale8
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.4)

@keyframes scale8r
    0% 
        transform: scale(1)
    100%  
        transform: scale(1.4)

@keyframes gone
    0% 
        transform: scale(0)
    100%  
        transform: scale(1)

@keyframes hide
    0% 
        transform: scale(1)
    100%  
        transform: scale(0)