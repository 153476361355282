@import '../styles/variables'

.jobs.onPopup
    align-self: flex-start
    width: 100%
    font-weight: 800
    font-size: 20px
    line-height: 46px
    letter-spacing: 0.01em
    text-transform: uppercase

    .jobs__item
        @include flexRow()
        cursor: pointer
        border-bottom: 2px solid #00000020
        margin-bottom: 7px

        &:last-child
            border: none

        &:hover
            color: #FFA666

    .chosen
        color: #FFA666
        span
            content: url('../images/arrow.svg')

        .jobs__item
            @include flexRow()
            cursor: pointer
            border-bottom: 2px solid #00000020
            margin-bottom: 7px

            &:last-child
                border: none

            &:hover
                color: #FFA666

        .chosen
            color: #FFA666
            span
                content: url('../images/arrow.svg')

    .item__descr
        display: none

        &_hide
            display: none

        .descr__items
            display: none
            
.jobs.onSlide
    margin-left: 11%
    @include mainFont()
    font-weight: 800
    font-size: 20px
    line-height: 46px
    letter-spacing: 0.01em
    text-transform: uppercase
    text-align: left
    width: 31%
    .chosen
        color: #000
        span
            content: url('../images/arrowD.svg')

    @media screen and ( max-width: 992px )
        margin-top: 3vh
        width: 90vw
        margin-left: auto
        margin-right: auto
        // margin-bottom: 30%

    @media screen and ( max-width: 480px )
        // margin-bottom: 45vh

    .jobs__item
        cursor: pointer
        @include flexRow()
        width: 100%
        border-bottom: 2px solid black
        margin-bottom: 7px
        transition: .2s

        &:hover
            color: #fff
            // border-bottom: 2px solid #fff
            transition: .2s

    .item__descr
        padding: 3vw
        max-height: 50vh
        width: 93.5vw
        border-radius: 10px
        border: 1px solid orange
        margin-left: -5vw
        background-color: #fff
        @include flexColumn()
        overflow-x: scroll
        z-index: 3

        &_hide
            display: none

        @media screen and ( min-width: 992px )
            display: none

        .descr__title
            width: 96%
            text-align: left
            @include mainFont()
            font-weight: 800
            font-size: 20px
            line-height: 46px
            letter-spacing: 0.01em

        .descr__items
            width: 95%

            @media screen and ( min-width: 480px )
                width: 95%

            .descr__item
                margin-bottom: 5vw
                @include mainFont()
                font-size: 16px
                line-height: 18px
                font-weight: normal
                text-transform: none
                width: 90%
                text-align: left
                display: flex

                &::before   
                    position: relative
                    margin-right: 15px
                    content: url("../images/V.svg")

        .descr__button
            padding-top: 13px
            padding-bottom: 13px
            font-weight: bold
            font-size: 16px
            line-height: 20px
            color: #FFFFFF
            width: 55%
            border: 1px solid #D28A55
            border-radius: 10px
            background-color: #FFA666
            
            @media screen and ( max-width: 480px )
                width: 95%

#last
    border: none