@import '../styles/variables'

.secondSlide
    width: 100vw
    @include flexRow
    justify-content: left
    z-index: 3
    background-color: transparent
    height: 100vh

    @media screen and ( max-width: 992px )

    .secondSlide__angel 
        z-index: -3
        width: 70vw
        right: -10%
        position: absolute
        @media screen and ( max-width: 992px )
            width: 70vw
            bottom: 30vw
        @media screen and ( max-width: 800px )
            width: 80vw
            right: 2%
            bottom: 60vw
        @media screen and ( max-width: 680px )
            width: 80vw
            right: 2%
            bottom: 80vw
        @media screen and ( max-width: 530px )
            width: 90vw
            right: 2%
            bottom: 115vw
        @media screen and ( max-width: 400px )
            width: 90vw
            right: 2%
            bottom: 140vw

    .secondSlide__gate
        z-index: -4
        width: 70vw
        right: -10%
        position: absolute
        @media screen and ( max-width: 992px )
            width: 70vw
            bottom: 30vw
        @media screen and ( max-width: 800px )
            width: 80vw
            right: 2%
            bottom: 60vw
        @media screen and ( max-width: 680px )
            width: 80vw
            right: 2%
            bottom: 80vw
        @media screen and ( max-width: 530px )
            width: 90vw
            right: 2%
            bottom: 105vw
        @media screen and ( max-width: 480px )
            width: 90vw
            right: 2%
            bottom: 115vw
        @media screen and ( max-width: 400px )
            width: 90vw
            right: 2%
            bottom: 140vw

    .secondSlide__appInfo
        width: 35vw
        @include flexColumn()
        margin-left: 7%
        text-align: left
        justify-content: left
        z-index: 5


        @media screen and ( max-width: 992px )
            width: 100%
            align-self: flex-end
            margin-bottom: 15vh
            
        .appInfo__header
            @include flexRow()
            justify-content: left
            width: 100%
            flex-wrap: wrap
            margin-bottom: 10px

            .appInfo__logo
                width: 80px
                height: 80px
                margin-right: 17px
                border-radius: 10px

            .appInfo__spans
                width: 70%

                .appInfo__title 
                    @include mainFont()
                    font-weight: bold
                    font-size: 28px
                    line-height: 34px
                    margin-bottom: 5px
                    width: 50%

                    @media screen and ( max-width: 2600px )
                        width: 60%
                    
                    @media screen and ( max-width: 2600px )
                        width: 90%
                    
                    @media screen and ( max-width: 1200px )
                        width: 100%

                    @media screen and ( max-width: 992px )
                        width: 50%

                    @media screen and ( max-width: 730px )
                        width: 70%

                    @media screen and ( max-width: 480px )
                        width: 100%
                    

                .appInfo__tag  
                    @include mainFont()
                    padding: 2px 18px
                    font-weight: 600
                    font-size: 14px
                    line-height: 20px
                    color: #FFFFFF
                    background: #000000
                    border-radius: 20px
                    margin-right: 6px

        .appInfo__descr
            @include mainFont()
            font-weight: 500
            font-size: 16px
            line-height: 22px
            color: #000000

            @media screen and ( max-width: 992px )
                max-height: 35vh
                overflow-y: scroll

            .appInfo__text
                margin-top: 30px
                width: 40%

                @media screen and ( max-width: 2600px )
                    width: 50%  
                @media screen and ( max-width: 2000px )
                    width: 70%
                @media screen and ( max-width: 1500px )
                    width: 90%
                @media screen and ( max-width: 1000px )
                    width: 90%
                @media screen and ( max-width: 992px )
                    width: 95%
                   
    
    .secondSlide__buttons
        margin-top: 30px
        padding: 0
        width: 100%
        @include flexRow()
        justify-content: left

        .secondSlide__button
            @include flexRow()
            @include mainFont()
            border-radius: 10px
            width: 46px
            height: 46px
            border: 3px 
            box-shadow: 0 .5px  #000
            font-weight: bold
            font-size: 16px
            line-height: 26px
            color: #fff
            border: 1px solid black
            margin-right: 11px
            img
                margin-right: auto
                margin-left: auto
                height: 30px
                width: 30px

            &:hover
                transition: .2s
                cursor: pointer
                background-color: $secondSlideMainColor

            &.main
                order: -1
                @include flexRow()
                width: 220px
                box-shadow: .5px 1px .5px .5px 
                justify-content: space-evenly
                transition: .2s
                background: $secondSlideMainColor
                border-color: $secondSlideSecondColor
                box-shadow: .5px 1px .5px .5px $secondSlideSecondColor

                img
                    margin-right: 0
                    margin-left: 0

                &:hover
                    transform: scale(1.02)
                    cursor: pointer
                    transition: .2s