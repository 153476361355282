@import '../styles/variables'

.fourthSlide
    width: 100vw
    height: 100vh
    overflow: hidden
    @include flexRow()

    @media screen and ( max-width: 992px )
        flex-direction: column
        justify-content: space-evenly
        overflow-y: visible
        height: auto
        min-height: 100vh
        
    .fourthSlide__background
        position: absolute
        z-index: -3
  
    .fourthSlide__textContainer
        text-align: left
        width: 34%
        margin-right: 10%
        @include mainFont()

        @media screen and ( max-width: 992px )
            order: -1
            width: 90%
            margin-right: auto
            margin-left: auto
            margin-top: 15vh

        .textContainer__title
            width: 90%
            font-weight: bold
            font-size: 64px
            line-height: 54px
            letter-spacing: 0.02em

            @media screen and ( max-width: 992px )
                font-size: 40px
                line-height: 36px
                width: 60vw
            @media screen and ( max-width: 730px )
                width: 80vw

        .textContainer__text
            margin-top: 30px
            font-weight: 500
            font-size: 16px
            line-height: 20px

            @media screen and ( max-width: 992px )
                margin-top: 3vw
                width: 60vw

            @media screen and ( max-width: 730px )
                width: 80vw
            

        .textContainer__button
            margin-top: 30px
            font-weight: bold
            font-size: 16px
            line-height: 20px
            text-align: center
            width: 220px
            height: 46px
            color: #fff
            background: #FFA666
            border-radius: 10px
            border: none
            box-shadow: .5px 1px #D28A55
            transition: .2s

            &:hover
                transform: scale(1.02)
                transition:.2s
