// @font-face
//     font-family: "Gilroy"
//     font-weight: 800
//     src : url("./fonts/Gilroy-Extrabold.otf")

// @font-face
//     font-family: "Gilroy"
//     font-weight: 700
//     src : url("./fonts/Gilroy-Bold.otf")

// @font-face
//     font-family: "Gilroy"
//     font-weight: 500
//     src : url("./fonts/Gilroy-Medium.otf")

// @font-face
//     font-family: "Gilroy"
//     font-weight: 400
//     src : url("./fonts/Gilroy-Regular.otf")

@font-face
    font-family : "Gilroy"
    font-weight : 500
    src : url("./fonts/5538953/dace493d-2c1a-478b-a98e-0e73f323a856.woff2") 

@font-face
    font-family : "Gilroy"
    font-weight : 800
    src : url("./fonts/5538957/9b941ec6-0652-468c-80c6-e7a624c32a75.woff2") 

@font-face
    font-family : "Gilroy"
    font-weight : 400
    src : url("./fonts/5538983/ab57e807-506b-4e1d-aed1-cb2b62dd7258.woff2") 
