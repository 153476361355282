@import "../styles/variables"
    
.popup-two
    z-index: 15
    width: 100vw
    height: 100vh
    position: absolute
    background: #00000050
    @include flexRow()
    margin: auto
    overflow: hidden
    @media screen and ( max-width: 992px )

    .popup__container
        width: 560px
        background-color: #fff
        border-radius: 24px
        @include flexColumn()
        padding: 40px
        margin: auto 
        background-color: #fff
        border-radius: 24px
        @media screen and ( max-width: 992px )
            width: 65%
            padding: 25px

        @media screen and ( max-width: 768px )
            width: 80%
            margin: auto 7%
            padding: 20px
            
        .popup__close
            @include flexRow()
            padding: 15px
            align-self: flex-end
            background-color: #fff
            border-radius: 50%
            margin-right: -68px
            margin-top: -68px
            img
                margin-right: auto
                margin-left: auto

            @media screen and ( max-width: 992px )
                margin-right: -55px
                margin-top: -55px

            @media screen and ( max-width: 768px )
                margin-right: -44px
                margin-top: -44px
                padding: 12px
        
        .container__title_hidden
            display: none

        .container__title
            width: 100%
            @include mainFont()
            font-weight: 800
            font-size: 32px
            line-height: 36px
            text-align: center
            letter-spacing: 0.01em
            text-transform: uppercase
            margin-bottom: 10px

            @media screen and ( max-width: 992px )
                font-size: 25px
                line-height: 28px
                margin-bottom: 10px

            @media screen and ( max-width: 480px )
                font-size: 20px
                line-height: 21px
                margin-bottom: 10px

        .container__form_hidden
            display: none

        .container__form
            width: 100%
            @include flexRow()
            flex-wrap: wrap
            
            .form__input
                @include mainFont()
                font-weight: normal
                font-size: 14px
                line-height: 16px
                padding: 15px
                background: #EDEDED
                border-radius: 8px
                width: 100%
                border: none
                margin-bottom: 10px
                
                @media screen and ( max-width: 768px )
                    font-size: 12px
                    line-height: 14px

                &:focus
                    outline-color: #FFA666
                
                &::placeholder
                    color: #000

            .short
                width: 44%

                .fileInput
                    display: none

                @media screen and ( max-width: 992px )
                    width: 100%


            .file 
                background: #FFA666
                box-shadow: 0.5px 1px 0.5px 0.5px #D28A55
                border-radius: 8px
                @include mainFont()
                font-weight: bold
                font-size: 12px
                line-height: 15px
                color: #FFFFFF
                text-align: center
                transition: .6s

                &.empty
                    transition: .6s
                    background: red

                &:hover
                    transform: scale(1.02)
                    transition:.2s
                    cursor: pointer

            .comment
                height: 90px
                resize: none
            
            .checkbox
                width: 100%
                background-color: transparent
                @include flexRow()
                text-align: left
                align-items: baseline
                display: block
                position: relative
                padding-left: 35px
                margin-bottom: 12px
                cursor: pointer
                -webkit-user-select: none
                -moz-user-select: none
                -ms-user-select: none
                user-select: none
                @include mainFont()
                font-weight: 500
                font-size: 14px
                line-height: 16px

                @media screen and ( max-width: 480px )
                    font-size: 12px
                    line-height: 15px
                    align-items: center
                    padding-top: 12px
                    margin: 0
                
                &.shaking
                    animation: shake 1s


                &:hover input ~ .checkmark 
                    background-color: #ccc

                input 
                    position: absolute
                    opacity: 0
                    cursor: pointer
                    height: 0
                    width: 0

                    // &:checked ~ .checkmark .orangeBorder
                    //     border: 2px solid #EDEDED

                    &:checked ~ .checkmark 
                        content: url("../images/check.svg")


                .checkmark 
                    position: absolute
                    top: 10px
                    left: 0
                    height: 22px
                    width: 22px
                    background: #EDEDED
                    border-radius: 4px
                    border:  2px solid #EDEDED
                    transform: rotate(0deg)

                    &.orangeBorder
                        transition: .6s
                        transform: rotate(360deg)
                        border:  2px solid #FFA666

                .checkmark:after 
                    content: ""
                    position: absolute
                    display: none
        
                .checkmark:after 
                    left: 9px
                    top: 5px
                    width: 5px
                    height: 10px
                    border: solid white
                    border-width: 0 3px 3px 0
                    -webkit-transform: rotate(45deg)
                    -ms-transform: rotate(45deg)
                    transform: rotate(45deg)

        .container__button
            width: 220px
            height: 46px
            background:  #FFA666
            border-radius: 10px
            border: none
            box-shadow: 0.5px 1px 0.5px 0.5px #D28A55
            font-weight: bold
            font-size: 16px
            line-height: 20px
            color: #FFFFFF
            margin-right: auto
            margin-left: auto
            transition: .2s

            &:hover
                transform: scale(1.02)
                transition:.2s
                cursor: pointer

            @media screen and ( max-width: 480px )
                width: 100%


