@import '../../styles/variables'

.rightSide__form
    width: 100%
    height: calc(100% + 10px)
    border: 2px solid #8470FA
    box-sizing: border-box
    border-radius: 16px
    padding: 30px
    @include flexColumn()
    position: relative
    z-index: 3

    &.shaking
        animation: shake 1s

    &-hide
        display: none

    @media screen and ( max-width: 992px )
        width: 70vw
        background-color: #fff
        padding: 10px

    @media screen and ( max-width: 730px )
        width: 90vw

    @media screen and ( max-width: 480px )
        width: 100vw
        background-color: #fff
        padding: 10px

    .form__title
        width: 100%
        font-weight: 800
        font-size: 32px
        line-height: 36px
        letter-spacing: 0.01em
        text-transform: uppercase
        margin-bottom: 10px
        text-align: center
        // -webkit-appearance: none !important

        @media screen and ( max-width: 992px )
            font-size: 20px
            line-height: 46px
            margin-bottom: 0
            text-align: left
            width: 99%

    .form__something
        // -webkit-appearance: none !important
        width: 100%
        height: 100%
        text-align: left
        @include flexColumn()
        align-items: flex-end
        margin-bottom: 8px

        .floating-label 
            // -webkit-appearance: none !important
            @include mainFont()
            margin-left: 4%
            position: relative
            pointer-events: none
            top: -35px
            transition: 0.2s ease all
            opacity: 1
            height: 0

        .form__input 
            // -webkit-appearance: none !important
            padding-left: 3%
            width: calc(96% - 2px)
            height: 45px
            border: none
            background: #EDEDED
            border-radius: 8px
            @include mainFont()
            font-size: 19px
            color: #000000        
            border: 1px solid transparent

            @media screen and ( max-width: 480px )
                height: 55px

            &::placeholder
                @include mainFont()
                font-size: 15px
                text-align: right
                padding-right: 3%

            &:focus 
                background: #fff
                border-radius: 8px 8px 0 8px 

            &:not(:placeholder-shown) ~ .floating-label
                align-self: flex-start
                top: -46px
                font-size: 11px
                opacity: .5
                height: 0
                @media screen and ( max-width: 480px )
                    top: -56px

            &:placeholder-shown ~ .floating-label
                align-self: flex-start
                top: -33px
                font-size: 15px
                opacity: 1
                height: 0
                @media screen and ( max-width: 480px )
                    top: -40px
                    font-size: 18px
                    
            &:placeholder-shown ~ .invalid-text
                opacity: 0                   

            &.invalid
                border: 1px solid #FA6056
                border-radius: 8px 8px 0 8px 
                background: #fff         

            &.invalid ~ .invalid-text
                display: inline-block
                height: 18px
                padding: 1px 10px
                background-color: #FA6056
                border-radius: 0 0 16px 16px
                align-self: flex-end
                opacity: 1
                margin-right: 0

            &.textarea
                resize: none
                height: 70px
                padding-top: 13px

                &:focus 
                    background: #fff
                    border-radius: 8px 8px 0 8px 

                &:not(:placeholder-shown) ~ .floating-label
                    align-self: flex-start
                    top: -83px
                    font-size: 11px
                    opacity: .5
                    height: 0
                    @media screen and ( max-width: 480px )
                        top: -83px

                &:placeholder-shown ~ .floating-label
                    align-self: flex-start
                    top: -70px
                    font-size: 15px
                    opacity: 1
                    height: 0

                    @media screen and ( max-width: 480px )
                        font-size: 18px
                        top: -73px

                &:placeholder-shown ~ .invalid-text
                    opacity: 0     

                &.invalid
                    border: 1px solid #FA6056
                    border-radius: 8px 8px 0 8px 
                    background: #fff         

                &.invalid ~ .invalid-text
                    display: inline-block
                    height: 18px
                    padding: 1px 10px
                    background-color: #FA6056
                    border-radius: 0 0 16px 16px
                    align-self: flex-end
                    opacity: 1
                    margin-right: 0 
                    margin-top: 84px

        .invalid-text
            @include mainFont()
            opacity: 0
            position: absolute
            margin-top: 48px
            z-index: 30
            // -webkit-appearance: none !important

            @media screen and ( max-width: 480px )
                margin-top: 58px


    .form__submit
        @include flexRow()
        width: 100%
        align-items: center
        // -webkit-appearance: none !important

        @media screen and ( max-width: 992px )
            @include flexColumn()
            // position: absolute
            bottom: 0
            width: 100%
            margin-top: 10px

        .submit__button
            align-self: flex-start
            // -webkit-appearance: none !important

    .checkbox
        width: 65%
        max-height: 32px
        background-color: transparent
        @include flexRow()
        text-align: left
        position: relative
        padding-left: 35px
        cursor: pointer
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none
        @include mainFont()
        font-weight: 500
        font-size: 14px
        line-height: 16px
        // -webkit-appearance: none !important

        @media screen and ( max-width: 992px )
            width: 100%
            // height: 30px
            align-self: flex-start
            margin-left: 5px
            margin-bottom: 2.5vh

        @media screen and ( max-width: 730px )
            margin-bottom: 3vh
            
        @media screen and ( max-width: 480px )
            width: 60%
            margin-bottom: 2vh
            
        &:hover input ~ .checkmark 
            background-color: #ccc

        &.shaking
            animation: shake 1s

        input 
            position: absolute
            opacity: 0
            cursor: pointer
            height: 0
            width: 0

            &:checked ~ .checkmark:after 
                // display: block
            &:checked ~ .checkmark 
                content: url("../../images/check.svg")

        .checkmark 
            position: absolute
            top: 0
            left: 0
            height: 21px
            width: 21px
            background: #EDEDED
            border-radius: 4px
            border: 1px solid #EDEDED

            &.purpleBorder
                transform: rotate(360deg)
                transition: .6s
                border: 1px solid #8470FA

            @media screen and ( max-width: 992px )
                top: -3px

            @media screen and ( max-width: 480px )
                top: 0px

        .checkmark:after 
            content: ""
            position: absolute
            display: none

        .checkmark:after 
            left: 9px
            top: 5px
            width: 5px
            height: 10px
            border: solid white
            border-width: 0 3px 3px 0
            -webkit-transform: rotate(45deg)
            -ms-transform: rotate(45deg)
            transform: rotate(45deg)
            
    .submit__button
        margin-left: 9px
        width: 190px
        height: 46px
        background: #8470FA
        border-radius: 10px
        border: none
        font-weight: bold
        font-size: 16px
        line-height: 20px
        color: #FFFFFF
        box-shadow: .5px 1px .5px .5px #725499
        transition: .2s

        &:hover
            transform: scale(1.02)
            transition:.2s

        @media screen and ( max-width: 992px )
            width: 98%
            margin-bottom: 20px
            margin-right: auto
            margin-left: auto