@import '../styles/variables'

.ruEn
    position: absolute
    right: 2vw
    bottom: 2vw
    @include flexRow()
    @include mainFont()
    // border: 1.5px solid #000
    // border-radius: 10px 
    font-weight: bold
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.01em
    text-transform: uppercase
    color: #000
    height: 29px
    padding: 0
    margin: 0

    &:hover
        cursor: pointer

        .blue 
            background-color: #1398d1

    @media screen and ( max-width: 992px )
        display: none

    .ruEn__item 
        height: 30px
        width: 50px
        @include flexColumn()
        justify-content: center
        border-radius: 10px
        border: 1px solid #000

        &:nth-child(1)
            border-right: 0
            border-radius: 10px 0 0 10px
        &:nth-child(2)
            border-radius: 0 10px 10px 0

    .blue 
        transition: .2s
        color: #fff
        border-radius: 9px
        background-color: #26BDFF


    


    