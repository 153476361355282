@import '../styles/variables'

.slider-horizontal__title
    position: absolute
    background-color: transtatent
    text-align: left
    width: 20%
    @include mainFont()
    font-weight: bold
    font-size: 64px
    line-height: 54px
    letter-spacing: 0.02em
    color: #fff
    right: 30%
    top: 27%
    z-index: 3

    @media screen and ( max-width: 1300px )
        top: 20%
        right: 35%

    @media screen and ( max-width: 992px )
        position: relative
        top: 0
        width: 0
        font-size: 6vw
        line-height: 7vw
        color: #000
        left: 5%
        margin-top: 20%
        margin-bottom: 0vw

    @media screen and ( max-width: 730px )
        margin-bottom: -5vw
    @media screen and ( max-width: 480px )
        margin-top: 20%
        width: 30%
        font-weight: bold
        font-size: 40px
        line-height: 36px
        color: #fff
        z-index: 2
        bottom: 140vw
        
.slider-horizontal
    @media screen and ( max-width: 992px )
        display: flex
        flex-direction: column-reverse
    .swiper-slide
        overflow-x: visible !important
        

    .swiper-pagination
        display: flex
        flex-direction: row !important
        justify-content: center !important
        height: 10vh !important
        z-index: 3 !important
        width: 100% !important
        position: fixed 

        @media screen and ( max-width: 992px )
            position: relative
            height: 5vh
            display: flex !important
            margin-top: -10vh
            // justify-self: flex-end

        .bullet-horisontal
            margin-right: 15px
            width: 30px
            height: 30px
            padding: 10px
            opacity: 1
            background-color: #fff
            border-radius: 50%
            transition: .2s

            &.bulletActive-horisontal
                background-color: $secondSlideMainColor

            &:hover
                transform: scale(1.1)

            img 
                width: 30px
                border-radius: 4px

.lala
    width: 100vw
    height: 100%
    height: -webkit-fill-available
    .slider-horizontal
        height: 100vh
        @media screen and ( max-width: 992px )
            height: 100%
            overflow: visible

        .swiper-wrapper
            -webkit-transform: translateZ(0)
            -webkit-transform: translate3d(0, 0, 0)
            -webkit-backface-visibility: hidden
            .swiper-slide
                -webkit-transform: translateZ(0)
                -webkit-transform: translate3d(0, 0, 0)
                -webkit-backface-visibility: hidden
                overflow-y: visible

            
