:root 
  --primary: #98ADFF
  --secondary: #98ADFF
  --alternate: #0000ff

$secondSlideMainColor: var(--primary)
$secondSlideSecondColor: var(--secondary)

@mixin flexRow()
    display: flex
    flex-direction: row
    justify-content: space-between 
    align-items: center

@mixin flexColumn()
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

@mixin transparent()
    background-color: transparent

@mixin mainFont()
    font-family: Gilroy

@mixin secondSlideButtonColors()
    &.green
        background: $secondSlideMainColor
        border-color: $secondSlideMainColor
        box-shadow: .5px 1px .5px .5px $secondSlideMainColor
    &.orange
        background: $secondSlideMainColor
        border-color: $secondSlideMainColor
        box-shadow: .5px 1px .5px .5px $secondSlideMainColor
    &.blue
        background: $secondSlideMainColor
        box-shadow: .5px 1px .5px .5px $secondSlideMainColor

@keyframes shake 
  10%, 90% 
    transform: translate3d(-1px, 0, 0)
  
  20%, 80% 
    transform: translate3d(2px, 0, 0)
  
  30%, 50%, 70% 
    transform: translate3d(-4px, 0, 0)
  
  40%, 60% 
    transform: translate3d(4px, 0, 0)
    
    