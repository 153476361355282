@import '../../styles/variables'

.ligth-blue
    &:before
        background: #BAEAFF
        transition: .8s
.blue
    &:before
        background: #98ADFF
        transition: .8s

.purple
    &:before
        background: rgb(132, 112, 250)
        transition: .8s

.green 
    &:before
        background: #A4EBA2 
        transition: .8s

.orange
    &:before
        background: #FFC6A2
        transition: .8s

.pink
    &:before
        transition: .8s
        background: #FFA59F

.red
    &:before
        transition: .8s
        background: #FA6056

.horisontalMode
    &:before
        transition: .8s
        background: $secondSlideMainColor

        