@import '../styles/variables'

.firstSlide 
    z-index: 3
    background-color: transparent
    width: 100vw
    @include flexRow
    background-repeat: no-repeat
    height: 100%
    height: -webkit-fill-available

    @media screen and ( min-width: 993px )
        height: 100vh 

    @media screen and ( max-width: 992px )
        @include flexColumn()
        justify-content: flex-start
        height: auto
        overflow: hidden

    @media screen and ( max-width: 480px )
        @include flexColumn()
        align-self: center
        justify-content: flex-start

    .firstSlide__castle 
        width: 30vw
        margin-left: 7%
        z-index: 3
        @media screen and ( max-width: 2600px )
            width: 35vw
        @media screen and ( max-width: 2300px )
            width: 40vw
            margin-left: 5%
        @media screen and ( max-width: 1600px )
            width: 50vw
            margin-left: 3%
        @media screen and ( max-width: 992px )
            width: 100vh

        @media screen and ( max-width: 480px )
            margin-top: 10%
            width: 130vw

    .firstSlide__textContaiter
        z-index: 3
        @include flexColumn
        width: 40vw
        margin-left: auto
        margin-right: 11%
        text-align: left

        @media screen and ( max-width: 992px )
            @include flexColumn
            align-self: center
            width: 75vw
            margin-left: 5%
            margin: 0
            text-align: left

        @media screen and ( max-width: 480px )
            @include flexColumn
            align-self: center
            width: 90vw
            margin-left: auto
            margin: 0
            text-align: left

        .firstSlide__title 
            @include mainFont()
            font-style: normal
            font-weight: bold
            font-size: 5.5vw
            line-height: 4.5vw
            letter-spacing: 0.02em
            color: #000000
            width: 100%
            @media screen and ( max-width: 992px )
                font-size: 10vw
                line-height: 9vw
            @media screen and ( max-width: 480px )
                font-size: 40px
                line-height: 36px
                width: 70vw
                align-self: flex-start

        .firstSlide__text
            @include mainFont
            font-style: normal
            font-weight: 500
            font-size: 16px
            line-height: 20px
            font-size: 1.3vw
            line-height: 1.5vw
            color: #000000
            margin-top: 2vw
            @media screen and ( max-width: 2300px )
                font-size: 1.3vw
                line-height: 1.5vw
            @media screen and ( max-width: 1600px )
                font-size: 1.2vw
                line-height: 1.4vw
            @media screen and ( max-width: 992px )
                font-size: calc( 1.5vw + 5px )
                line-height: 3vw

            @media screen and ( max-width: 480px )
                text-align: left
                align-self: flex-start
                width: 90%
                margin-top: 15px
                font-size: 14px
                line-height: 18px

        .firstSlide__buttons
            margin-top: 2vw
            margin-bottom: 2vw
            width: 100%
            align-content: left
            @include flexRow()
            justify-content: flex-start

            @media screen and ( max-width: 992px )
                @include flexRow()
                justify-content: center

            @media screen and ( max-width: 480px )
                @include flexRow()
                margin-top: 3.5vw


            .firstSlide__button
                @include mainFont()
                font-weight: 600
                font-size: 1.1vw
                line-height: 1.3vw
                color: #000000
                background-color: transparent
                border-radius: 10px
                border: 1px solid transparent
                height: 66px
                transition:.2s

                @media screen and ( max-width: 2300px )
                    height: 56px
                    font-size: 1.1vw
                    line-height: 1.3vw
                @media screen and ( max-width: 1600px )
                    height: 46px
                    font-size: 16px
                    line-height: 20px

                &_white
                    transition: .4s

                    &:hover
                        color: #26BDFF


                @media screen and ( max-width: 480px )
                    margin-right: 10%

                &_blue
                    width: 16vw
                    background: linear-gradient(0deg, #26BDFF, #26BDFF), #FFFFFF
                    font-weight: bold
                    color: #FFFFFF
                    margin-right: 3vw
                    box-shadow: .5px 1px .5px .5px #118EC5
                    transition:.2s

                    &:hover
                        transform: scale(1.02)
                        transition:.2s

                    @media screen and ( max-width: 992px )
                        width: 35vw

                    @media screen and ( max-width: 480px )
                        width: 50%

    &.even 
        flex-direction: row-reverse
        background-position: right

        .firstSlide__textContaiter
            @include flexColumn
            width: 40%
            margin-left: 5%
            margin-right: auto
