@import '../styles/variables'

.burgerMenu__hide  
    display: none

    @media screen and ( max-width: 992px )
        position: fixed
        left: 0
        width: calc(100vw - 40px)
        height: calc(100vh - 48px)
        background-color: #fff
        @include flexColumn()
        justify-content: flex-start
        padding: 24px 20px
        @include mainFont()
        z-index: 10
        transition: .8s
        transform: translate(-100vw)
        

        .burgerMenu__header
            @include flexRow()
            width: 100%
            margin-right: -10px

            .header__close
                @include flexRow()
                height: 40px
                width: 40px
                border-radius: 50%
                border: 1px solid rgb(0, 0, 0, .3)

                img
                    margin-right: auto
                    margin-left: auto

        .burgerMenu__lang
            width: 100%
            text-align: left
            margin-top: 20px
            font-weight: bold
            font-size: 18px
            line-height: 18px
            letter-spacing: 0.02em

        .burgerMenu__nav
            margin-top: 10vh
            font-weight: 800
            font-size: 22px
            line-height: 60px
            letter-spacing: 0.03em
            text-transform: uppercase
            @include flexColumn()
            width: calc(100vw - 40px)
            text-align: left

            .nav__item
                width: 100%
                margin-top: 3vh
                @include flexRow()
                justify-content: flex-start
                height: 5vh
                &.red
                    color: #F70303

            .coin
                height: 7px
                width: 7px
                padding: 5px
                border-radius: 50%
                background-color: #F70303
                color: #fff
                margin-bottom: 20px
                @include mainFont()
                font-weight: 900
                font-size: 11px
                line-height: 9px

        .burgerMenu__buttons
            @include flexRow()
            width: calc(100vw - 40px)
            position: absolute
            bottom: 5vw

            .button__feedback
                width: 65%
                height: 50px
                background: #CA9EF6
                border-radius: 12px
                border: none
                font-weight: bold
                font-size: 18px
                line-height: 0px
                letter-spacing: 0.02em
                color: #FFF
            
            a 
                width: 32%
                
                .button__discord
                    width: 100%
                    height: 50px
                    border-radius: 12px
                    border: none
                    background-color: #8B9DFE

                    img
                        height: 48px
        
    
.burgerMenu
    position: fixed
    width: calc(100vw - 40px)
    height: calc(100vh - 48px)
    background-color: #fff
    @include flexColumn()
    justify-content: flex-start
    padding: 24px 20px
    @include mainFont()
    position: absolute
    right: 0
    z-index: 10
    transition: .8s
    transform: translate(0)

    .burgerMenu__header
        @include flexRow()
        width: 100%
        margin-right: -10px

        .header__close
            @include flexRow()
            height: 40px
            width: 40px
            border-radius: 50%
            border: 1px solid rgb(0, 0, 0, .3)

            img
                margin-right: auto
                margin-left: auto

    .burgerMenu__lang
        width: 50%
        text-align: left
        margin-top: 20px
        font-weight: bold
        font-size: 18px
        line-height: 18px
        letter-spacing: 0.02em
        align-self: flex-start

    .burgerMenu__nav
        margin-top: 10vh
        font-weight: 800
        font-size: 22px
        line-height: 60px
        letter-spacing: 0.03em
        text-transform: uppercase
        @include flexColumn()
        width: calc(100vw - 40px)
        text-align: left

        .nav__item
            width: 100%
            margin-top: 3vh
            @include flexRow()
            justify-content: flex-start
            height: 5vh
            transition: .2s
            &.red
                color: #F70303

        .coin
            height: 7px
            width: 7px
            padding: 5px
            border-radius: 50%
            background-color: #F70303
            color: #fff
            margin-bottom: 20px
            @include mainFont()
            font-weight: 900
            font-size: 11px
            line-height: 9px

    .burgerMenu__buttons
        @include flexRow()
        align-items: flex-start 
        width: calc(100vw - 40px)
        position: absolute
        bottom: 5vw

        .button__feedback
            width: 65%
            height: 50px
            background: #CA9EF6
            border-radius: 12px
            border: none
            font-weight: bold
            font-size: 18px
            line-height: 0px
            letter-spacing: 0.02em
            color: #FFF
        
        a 
            width: 32%
            
            .button__discord
                display: flex
                flex-direction: column-reverse
                align-items: center
                width: 100%
                height: 50px
                border-radius: 12px
                border: none
                background-color: #8B9DFE
                    
        