@import "../styles/variables"
.sixthSlide
    background-color: transparent
    height: 100vh
    @include flexRow()

    @media screen and ( max-width: 992px )
        @include flexColumn() 
        justify-content: flex-start
        height: auto
        overflow-x: hide
        padding-bottom: 30vh

    .sixthSlide__girl
        z-index: -3
        width: 50%
        position: absolute
        left: 16%

        @media screen and ( max-width: 992px )
            position: relative
            width: 102%
            overflow: hidden
            margin-top: -100%
            margin-left: 30%

        @media screen and ( max-width: 730px )
            margin-top: -150%
            width: 132%

        @media screen and ( max-width: 480px )
            margin-top: -230%
            margin-left: 30%
            width: 162%

    
    .sixthSlide__leftSide
        @include mainFont()
        @include flexColumn()
        width: 30%
        margin-left: 11%
        text-align: left
        align-items: flex-start

        @media screen and ( max-width: 992px )
            width: 100%

        .leftSide__title
            width: 100%
            font-weight: bold
            font-size: 64px
            line-height: 54px
            letter-spacing: 0.02em
            color: #FFFFFF

            @media screen and ( max-width: 992px )
                // margin-top: 10%
                color: #000
                width: 70vw
                align-self: flex-start

            @media screen and ( max-width: 480px )
                margin-top: 20%
                width: 45vw
                align-self: flex-start
                font-size: 11.5vw
                line-height: 12vw

        .leftSide__mail
            margin-top: 30px
            font-weight: 600
            width: 230px
            font-size: 24px
            line-height: 20px
            letter-spacing: 0.02em
            color: #000
            transition: .2s

            &:hover
                transform: scale(1.02)
                transition:.2s

        .leftSide__links
            margin-top: 30px
            @include flexColumn()
            width: 100%
            font-weight: 500
            font-size: 14px
            line-height: 16px
            display: flex
            align-items: center

            span
                margin-top: 3px
                font-weight: bold
                font-size: 24px
                line-height: 20px
                letter-spacing: 0.02em
                color: #FFFFFF

            .links__item
                margin-bottom: 20px
                flex-wrap: wrap
                align-self: flex-start
                background-color: transparent
                border: none

                a
                    @include flexRow()
                    .item__text
                        @include flexColumn()
                        align-items: flex-start
                        color: #000

                    .item__image
                        @include flexRow()
                        justify-content: space-around
                        width: 50px
                        height: 50px
                        background-color: #fff
                        border-radius: 50%
                        margin-right: 10px


    .sixthSlide__rightSide
        width: 40%
        margin-right: 11%
        @include flexColumn()

        @media screen and ( max-width: 992px )
            width: 100%
            margin-right: auto
            margin-left: auto


        .rightSide__copyright
            margin-top: 42px
            @include mainFont()
            font-weight: 500
            font-size: 14px
            line-height: 16px

            @media screen and ( max-width: 992px )
                margin-bottom: 22px
        

        

