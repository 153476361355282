@import '../styles/variables'

.fifthSlide
    @include flexRow()
    overflow-x: hidden
    overflow-y: hidden
    height: 100vh
    z-index: 10

    @media screen and ( max-width: 992px )
        @include flexColumn()
        flex-direction: column-reverse
        justify-content: space-evenly
        justify-content: flex-start
        height: auto

    .fifthSlide__textContainer
        @include mainFont()
        font-weight: 500
        text-align: left
        margin-left: 11%
        z-index: 3
        width: 35%

        @media screen and ( max-width: 2600px )
            width: 40%
        @media screen and ( max-width: 1500px )
            width: 50%
        @media screen and ( max-width: 1000px )
            width: 60%
        @media screen and ( max-width: 992px )
            align-self: center
            width: 70%
            margin: 0

        @media screen and ( max-width: 480px )
            align-self: center
            width: 90%
            height: 100%
            align-content: flex-end
            margin: 0

        .textContainer__text
            font-size: 1.3vw
            line-height: 1.4vw
            margin-bottom: 25px
            width: 70%
            align-self: flex-start

            @media screen and ( max-width: 992px )
                width: 60vw
                margin: 0
                margin-top: 20px
                font-size: 20px
                line-height: 24px

            @media screen and ( max-width: 480px )
                width: 80vw
                margin: 0
                margin-top: 20px
                font-size: 16px
                line-height: 20px
    
        .textContainer__list
            @include flexRow()
            flex-wrap: wrap
            margin-bottom: 25px
            font-size: 1.1vw
            line-height: 1.3vw
            width: 100%
            align-self: self-start

            @media screen and ( max-width: 480px )
                width: 100%

            .list__item
                @include flexRow()
                justify-content: flex-start
                width: 50%
                align-items: stretch

                p
                    margin-top: 15px
                    @media screen and ( max-width: 992px )
                        width: 80%

                @media screen and ( max-width: 992px )
                    @include flexColumn()
                    margin-top: 3%
                    font-size: 16px
                    line-height: 16px
                    text-align: left
                    width: 50%

                .item__image
                    width: 75px
                    height: 75px
                    margin-right: 15px
    
    .textContainer__button
        margin-top: 16px
        font-weight: bold
        font-size: 16px
        line-height: 20px
        text-align: center
        width: 220px
        height: 46px
        color: #fff
        background: #FFA666
        border-radius: 10px
        border: none
        box-shadow: .5px 1px #D28A55
        transition: .2s

        &:hover
            transform: scale(1.02)
            transition:.2s

        @media screen and ( max-width: 992px )
            margin-bottom: 30%
        
        

.fifthSlide__title
    @include mainFont()
    height: 50%
    font-weight: bold
    font-size: 5.5vw
    line-height: 4.5vw
    letter-spacing: 0.02em
    color: #FFFFFF
    width: 30%
    margin-right: 20%
    text-align: left

    @media screen and ( max-width: 992px )
        font-size: 30px
        line-height: 33px
        width: 55vw
        align-self: flex-start
        color: #000
        height: 10%
        margin-top: 30vh
        margin-right: 0
        margin-left: 15vw

    @media screen and ( max-width: 480px )
        width: 50vw
        margin-left: 5vw
        margin-top: 35%

.fifthSlide__rocketBig
    position: absolute
    right: 2%
    top: 20%
    transform: scale(1.3)
    overflow: hidden
    width: 20vw

    @media screen and ( max-width: 2600px )
        width: 25vw
        top: 25%
    @media screen and ( max-width: 2300px )
        width: 35vw
    @media screen and ( max-width: 1600px )
        width: 40vw

    @media screen and ( max-width: 992px )
        margin-bottom: -60%
        margin-top: 10%
        margin-right: -40%
        position: relative
        right: calc( 10vw - 100px )
        top: -9vw
        z-index: 1
        width: 30vw
    @media screen and ( max-width: 730px )
        top: -5vw
        width: 30vw
    @media screen and ( max-width: 480px )
        margin-top: 10vh
        width: 50vw
        left: calc( 10% - 20px )
        z-index: 1



        