$ballColor: #BAEAFF

.ball-left
    width: 0
    height: 0
    position: absolute
    top: 35% 
     -webkit-transition: .5s
    -moz-transition: .5s
    -o-transition: .5s
    transition: .5s
    -webkit-transform: translateZ(0)
    -webkit-backface-visibility: hidden
    overflow-y: hidden
    @media screen and ( max-width: 922px )
        top: 0

    &.show
        display: block
        width: 800px
        height: 800px
        -webkit-transition: 3s
        -moz-transition: 3s
        -o-transition: 3s
        transition: 3s

        @media screen and ( max-width: 2600px )
            width: 600px
            height: 600px
        @media screen and ( max-width: 1800px )
            width: 450px
            height: 450px
        @media screen and ( max-width: 1500px )
            width: 400px
            height: 400px
        @media screen and ( max-width: 1000px )
            width: 300px
            height: 300px

    &:before 
        background: $ballColor
        border-radius: 100%
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        content: ''

    &:nth-child(1) 
        left: -7%
        top: 40%
        -webkit-animation: gone 3s, scale1 2s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale1 2s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale1 2s infinite  alternate ease-in-out
        animation: gone 3s, scale1 2s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
        
    &:nth-child(2) 
        left: 10%
        top: 43%
        -webkit-animation: gone 3s, scale2 4s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale2 4s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale2 4s infinite  alternate ease-in-out
        animation: gone 3s, scale2 4s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
    &:nth-child(3) 
        left: 17%
        top: 30%
        -webkit-animation: gone 3s, scale3 4s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale3 4s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale3 4s infinite  alternate ease-in-out
        animation: gone 3s, scale3 4s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
    &:nth-child(4) 
        left: 17%
        top: 50%
        -webkit-animation: gone 3s, scale4 5s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale4 5s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale4 5s infinite  alternate ease-in-out
        animation: gone 3s, scale4 5s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
    &:nth-child(5) 
        left: 27%
        top: 10%
        -webkit-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        animation: gone 3s, scale4 3s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
    &:nth-child(6) 
        left: -7%
        top: 20%
        -webkit-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        animation: gone 3s, scale4 3s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
    &:nth-child(7) 
        left: 10%
        top: 10%
        -webkit-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale4 3s infinite  alternate ease-in-out
        animation: gone 3s, scale4 3s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s
    &:nth-child(8) 
        left: 7%
        top: 5%
        -webkit-animation: gone 3s, scale4 5s infinite  alternate ease-in-out
        -moz-animation: gone 3s, scale4 5s infinite  alternate ease-in-out
        -o-animation: gone 3s, scale4 5s infinite  alternate ease-in-out
        animation: gone 3s, scale4 5s infinite alternate ease-in-out
        -webkit-animation-delay: 0s, 5s
        -moz-animation-delay: 0s, 5s
        -o-animation-delay: 0s, 5s
        animation-delay: 0s, 5s



