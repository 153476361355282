@import "../styles/variables"
    
.popup
    overflow-x: hidden
    z-index: 15
    width: 100vw
    height: 100vh
    background: #00000050
    position: absolute
    overflow-y: scroll

    @media screen and ( max-width: 992px )
        display: none

    .popup__container
        padding: 40px
        margin: 3% auto 0 auto
        width: 70vw
        min-height: 80vh
        height: auto
        background-color: #fff
        border-radius: 24px
        @include flexRow()
        @media screen and ( max-width: 2600px )
            width: 70%
        @media screen and ( max-width: 1500px )
            width: 83%

        .popup__close
            @include flexRow()
            padding: 13px
            align-self: flex-start
            background-color: #fff
            border-radius: 50%
            margin-top: -67px
            margin-left: calc( 70vw + 27px )
            position: absolute
            @media screen and ( max-width: 2600px )
                margin-left: calc( 70vw + 27px )
            @media screen and ( max-width: 1500px )
                margin-left: calc( 83% + 27px )
            @media screen and ( max-width: 1000px )

            img
                margin-right: auto
                margin-left: auto

            &:hover
                cursor: pointer

        .popup__jobs
            align-self: flex-start
            @include flexColumn()
            height: 100%
            width: 45%
            text-align: left
            display: flex
            justify-content: flex-start

            .jobs__header
                width: 100%
                @include mainFont()
                font-weight: 800
                font-size: 32px
                line-height: 36px
                letter-spacing: 0.01em
                text-transform: uppercase
                margin-bottom: 70px

            .jobs__list
                align-self: flex-start
                width: 100%
                font-weight: 800
                font-size: 20px
                line-height: 46px
                letter-spacing: 0.01em
                text-transform: uppercase
                

                .jobs__item
                    @include flexRow()
                    cursor: pointer
                    border-bottom: 1px solid #00000020
                    margin-bottom: 7px

                    &:last-child
                        border: none

                    &:hover
                        color: #FFA666

                .chosen
                    color: #FFA666
                    span
                        content: url('../images/arrow.svg')
            .jobs__button
                margin-top: 40px
                width: 100%
                height: 46px
                background: #FFA666
                color: #fff
                border: 1px solid #D28A55
                border-radius: 10px
                font-weight: bold
                font-size: 16px
                line-height: 20px
                transition: .2s

                &:hover
                    transform: scale(1.02)
                    transition:.2s

        .popup__description
            margin-left: 40px
            @include flexColumn()
            @include mainFont()
            width: 45%
            justify-content: flex-start
            // overflow-y: scroll
            min-height: 80vh
            
            .description__header
                @include flexColumn()
                align-items: flex-start
                width: 100%
                font-weight: 800
                font-size: 32px
                line-height: 36px
                letter-spacing: 0.01em
                text-transform: uppercase
                margin-bottom: 34px

                span
                    font-weight: normal
                    font-size: 16px
                    line-height: 20px

            .description__list
                @include flexColumn()
                width: 100%
                align-items: flex-start
                text-align: left
                font-weight: bold
                font-size: 28px
                line-height: 56px

                .description__item
                    @include flexRow()
                    margin-top: 18px
                    font-weight: normal
                    font-size: 14px
                    line-height: 18px
                    align-items: start

                    &:last-child
                        margin-bottom: 40px

                    &::before
                        margin-right: 8px
                        content: url("../images/V.svg")

.disabled
    display: none    