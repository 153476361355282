@import '../styles/variables'

.header
    position: fixed
    width: -webkit-fill-available
    padding: 14px
    @include flexRow
    @include transparent
    z-index: 9
    transition: 3s

    @media screen and ( max-width: 992px )
        padding: 24px 20px
        width: calc( 100vw - 40px )

    &.gradient
        @media screen and ( max-width: 992px )
            transition: 3s
            background: linear-gradient(to top, transparent , #00000086 100%)


    .header__logo
        transition: .2s
        cursor: pointer

        &:hover
            transform: scale(1.02)
            transition: .2s
        @media screen and ( max-width: 480px )
            margin-left: 0
                
    .header__nav
        @include mainFont()
        @include flexRow
        font-size: 14px
        font-weight: 600
        line-height: 17px
        @include flexRow()
        
        li
            cursor: pointer
            transition: .2s
            margin-right: 3vw

            &:hover
                font-weight: 800
                transition: .2s

        @media screen and ( max-width: 992px )
            display: none


    .header__contacts
        @include flexRow
        @media screen and ( max-width: 992px )
            display: none

        .contacts__contacts
            @include mainFont()
            font-weight: 500
            font-size: 14px
            line-height: 16px
            padding: 11px 28px
            border: 1px solid #000
            box-sizing: border-box
            border-radius: 10px
            background-color: transparent
            text-align: center

        .contacts__discord
            background-color: #fff
            background-color: transparent
            margin-left: 13px
            width: 51px
            height: 51px
            border-radius: 50%
            border: 1px solid #000
            box-sizing: border-box

            img
                margin-top: 3px

            // .discord__background
            //     top: 13px
            //     right: 10px
            //     position: absolute
            //     z-index: -10
            //     width: 60px
            //     height: 53px
            //     @include flexRow()
            //     background-image: url('../images/cloudShape.svg')
            //     transform: scale(.7) rotate(90deg)
            //     transition: .4s

    .header__burger
        display: none

        @media screen and ( max-width: 992px )
            display: block
            @include flexRow()
            justify-content: flex-start
            margin-top: -2px


    @media screen and ( max-width: 992px )
        .coin
            position: absolute
            height: 7px
            width: 7px
            padding: 5px
            border-radius: 50%
            background-color: #F70303
            color: #fff
            top: 15px
            right: 7px
            @include mainFont()
            @include flexColumn()
            font-weight: 900
            font-size: 11px
            line-height: 9px

