@import '../styles/variables'

.buttonDown
    width: 50px
    height: 50px
    border: 1px solid black
    border-radius: 50%
    background: transparent
    position: absolute
    left: calc( 50% - 20px )
    bottom: 2vw
    transition: .2s
    z-index: 10

    &:hover 
        padding-top: 7px
        transition: .2s
    
    .buttonDown__arrow
        margin-left: -2px
        margin-top: 2px
        transform: scale(1.2)

    @media screen and ( max-width: 992px )
        display: none


