@import '../styles/variables'

.thirdSlide
    justify-self: flex-start
    width: 100vw
    height: 100vh
    background-repeat: no-repeat
    background-size: cover
    @include flexRow()

    @media screen and ( max-width: 992px )
        min-height: 100vh
        height: auto

    .thirdSlide__caslte
        width: 40vw
        margin-left: 5%
        z-index: 3

        @media screen and ( max-width: 2600px )
            width: 40vw
            margin-left: 5%
        @media screen and ( max-width: 2300px )
            width: 45vw
            margin-left: 3%
        @media screen and ( max-width: 1600px )
            width: 50vw
            margin-left: 0%
        @media screen and ( max-width: 992px )
            display: none
            z-index: 10

    .thirdSlide__textContaiter
        width: 25%
        margin-right: 11%

        @media screen and ( max-width: 2600px )
            width: 30%
        @media screen and ( max-width: 2300px )
            width: 35%
        @media screen and ( max-width: 1500px )
            width: 40%

        @media screen and ( max-width: 992px )
            width: 70vw
            margin-left: 10vw

        @media screen and ( max-width: 480px )
            width: 90vw
            margin-right: auto
            margin-left: auto


        .textContainer__numbers
            @include mainFont()
            font-weight: bold
            font-size: 80px
            -webkit-text-fill-color: white 
            -webkit-text-stroke-width: 1px
            -webkit-text-stroke-color: black
            @include flexRow()
            // width: 80%
            margin-top: 36px
            // @media screen and ( max-width: 992px )
            //     width: 70%
            
            .numbers__item
                align-self: baseline
                margin-right: 15px
            
                .item__number
                    text-align: center
                    height: 56px
                    text-shadow: 0px 10px 0px #8BEB89, 0px 11px 2px black

                .item__text
                    text-align: center
                    margin-top: 40px
                    font-style: normal
                    font-weight: 600
                    font-size: 16px
                    line-height: 18px
                    color: #000000
                    -webkit-text-fill-color: black 
                    -webkit-text-stroke-width: 0px
                    -webkit-text-stroke-color: black
                    text-shadow: 0px 0px 0px black

                    @media screen and ( max-width: 480px )
                        font-size: 14px
                        line-height: 16px



        .textContainer__title
            @include mainFont()
            font-weight: 800
            font-size: 32px
            line-height: 36px
            display: flex
            align-items: center
            letter-spacing: 0.01em
            text-transform: uppercase

            @media screen and ( max-width: 992px )
                font-size: 40px
                line-height: 36px
                color: #fff
                text-align: left
                text-transform: none
                width: 90%


        .textContainer__text
            margin-top: 30px
            text-align: left
            @include mainFont()
            font-weight: 500
            font-size: 16px
            line-height: 20px


    

