@import '../../styles/variables'

.loader
    position: absolute
    height: 100%
    filter: blur(5px) contrast(1)
    box-sizing: border-box
    border-radius: 16px
    @include flexRow()
    justify-self: center
    z-index: 5
    opacity: 1

    .loader__ball
        width: 50px
        height: 50px
        border-radius: 100%
        background-color: #5143F0
        transform: scale(0)

        &:nth-child(odd)
            animation: spinLeft 2.5s 0.2s linear both infinite

        &:nth-child(even)
            animation: spinRight 1s 0.4s linear both infinite

        &:nth-child(2n)
            animation: pulse 2s ease-in-out 0.1s infinite

    &.onPopup
        position: absolute
        height: 65%
        filter: blur(5px) contrast(1)
        box-sizing: border-box
        border-radius: 16px
        @include flexRow()
        justify-self: center
        z-index: 5
        opacity: 1

        .loader__ball
            width: 50px
            height: 50px
            border-radius: 100%
            background-color: #FFA666
            transform: scale(0)


@keyframes pulse
    0% 
        transform: scale(0)
    50% 
        transform: scale(1)
    100%
        transform: scale(0)
  


@keyframes spinLeft
    from
        transform: rotate(0deg) translate(50px) scale(.5)
    to
        transform: rotate(360deg) translate(50px) scale(1)

  

@keyframes spinRight
    from
        transform: rotate(360deg) translate(50px)
        transform: scale(.5)
    
    to
        transform: rotate(0deg) translate(50px)
        transform: scale(1)
  
