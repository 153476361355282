@import '../styles/variables'

.popupTwoSent
    width: 100%
    height: 100%
    box-sizing: border-box
    border-radius: 16px
    padding: 37px 91px 48px 91px
    @include flexColumn()
    justify-content: flex-start
    position: relative
    z-index: 3
    text-align: center

    @media screen and ( max-width: 992px )
        background-color: #fff
        width: 70vw
        padding: 37px 31px 48px 31px
    
    @media screen and ( max-width: 730px )
        background-color: #fff
        width: 80vw
        padding: 37px 21px 48px 21px
    
    @media screen and ( max-width: 480px )
        background-color: #fff
        width: 70vw
        padding: 37px 11px 48px 11px

    .sent__image
        width: 129px
        margin-bottom: 28px

    .sent__title
        @include mainFont()
        font-weight: 800
        font-size: 32px
        line-height: 36px
        letter-spacing: 0.01em
        text-transform: uppercase
        margin-bottom: 19px

    .sent__text
        @include mainFont()
        font-weight: normal
        font-size: 14px
        line-height: 20px
        margin-bottom: 16px
        
        .sent_email
            color: #0000EE

    .sent__button
        height: 46px
        width: 90%
        background: #A4EBA2
        border: 1px solid #84CA82
        border-radius: 10px
        @include mainFont()
        font-weight: bold
        font-size: 16px
        line-height: 20px
        color: #FFFFFF
        margin-top: 20px
        @media screen and ( max-width: 480px )
            width: 80%

    &-hide
        display: none

.popupTwoSent_err
    width: 100%
    height: 100%
    box-sizing: border-box
    border-radius: 16px
    padding: 37px 91px 48px 91px
    @include flexColumn()
    justify-content: flex-start
    position: relative
    z-index: 3
    text-align: center

    @media screen and ( max-width: 992px )
        background-color: #fff
        width: 70vw
    
    @media screen and ( max-width: 640px )
        background-color: #fff
        width: 90vw
    
    @media screen and ( max-width: 480px )
        background-color: #fff
        width: 100vw

    .sent__image
        width: 129px
        margin-bottom: 28px
        transform: rotate(180deg)

    .sent__title
        @include mainFont()
        font-weight: 800
        font-size: 32px
        line-height: 36px
        letter-spacing: 0.01em
        text-transform: uppercase
        margin-bottom: 19px

    .sent__text
        @include mainFont()
        font-weight: normal
        font-size: 14px
        line-height: 20px
        margin-bottom: 16px
        
        .sent_email
            color: #0000EE

    .sent__button
        height: 46px
        width: 90%
        background: #FA6056
        border: 1px solid #FA6056
        box-shadow: 0.5px 1px #B8252F
        border-radius: 10px
        @include mainFont()
        font-weight: bold
        font-size: 16px
        line-height: 20px
        color: #FFFFFF


    &-hide
        display: none